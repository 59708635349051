import TaskList from "./TaskList";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Spinner from "./Spinner";

const TaskListLoader = () => {
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        setLoading(true)
        fetch('/api/tasks')
            .then(response => response.json())
            .then(data => setTasks(data))
            .finally(() => setLoading(false));
    }, []);

    const updateTask = (id, updatedTask) => {
        console.log(updatedTask);
        fetch(`/api/tasks/${id}`, {
            method: 'PUT', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(updatedTask)
        })
            .then(() => fetch('/api/tasks')
                .then(response => response.json())
                .then(data => setTasks(data)))
    };

    const deleteTask = (id) => {
        fetch(`/api/tasks/${id}`, {
            method: 'DELETE'
        })
            .then(() => fetch('/api/tasks')
                .then(response => response.json())
                .then(data => setTasks(data)))
    };

    return (<>
        <Link to="/tasks">Add</Link>
        {loading ? <Spinner/> : <TaskList tasks={tasks} onDeleteTask={deleteTask} onUpdateTask={updateTask}/>}
    </>)
}

export default TaskListLoader;