import React from 'react';
import './App.css';
import TaskListLoader from "./components/TaskListLoader";
import Header from "./layout/Header";
import Footer from "./layout/Footer";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <Header/>
            </header>
            <main  className="App-main">
                <TaskListLoader/>
            </main>
            <footer className="App-footer">
                <Footer/>
            </footer>
        </div>
    )
}

export default App;
