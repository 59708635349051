import TaskForm from "./TaskForm";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Spinner from "./Spinner";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

const TaskFormLoader = () => {

    const {taskId} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [task, setTask] = useState({});

    useEffect(() => {
        if (taskId) fetchData(taskId);
    }, [taskId]);

    const fetchData = (taskId) => {
        setLoading(true)
        fetch('/api/tasks/' + taskId, {
            method: 'GET', headers: {'Content-Type': 'application/json'}
        })
            .then(response => response.json())
            .then(data => setTask(data))
            .finally(() => setLoading(false))
    }

    const handleAddTask = (updateValue) => {
        if (updateValue.id) {
            updateTask(updateValue.id, task);
        } else {
            addTask(task);
        }
    };

    const updateTask = (id, updatedTask) => {
        console.log(updatedTask);
        fetch(`/api/tasks/${id}`, {
            method: 'PUT', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(updatedTask)
        })
            .then(() => fetch('/api/tasks')
                .then(response => response.json())
                .then(data => navigate('/')))
    };

    const addTask = (task) => {
        fetch('/api/tasks', {
            method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(task)
        })
            .then(() => fetch('/api/tasks')
                .then(response => response.json())
                .then(data => navigate('/')))
    };


    return (
        <>

            <div className="App">
                <header className="App-header">
                    <Header/>
                </header>
                <main className="App-main">
                    {loading ? <Spinner/> : <TaskForm task={task} setTask={setTask} onAddTask={handleAddTask}/>}
                </main>
                <footer className="App-footer">
                    <Footer/>
                </footer>
            </div>
        </>
    )
}

export default TaskFormLoader