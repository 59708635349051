import React from 'react';
import TaskListItem from "./TaskListItem";

const TaskList = ({tasks, onDeleteTask, onUpdateTask}) => {

    const handleToggleTaskCompletion = (id) => {
        const taskToToggle = tasks.find(task => task.id === id);
        if (taskToToggle) {
            onUpdateTask(id, {...taskToToggle, completed: !taskToToggle.completed});
        }
    };

    const handleDeleteTask = (id) => {
        onDeleteTask(id);
    };

    return (
        <ul className="task-list">
            {tasks.map(task => (
                <TaskListItem
                    key={task.id}
                    task={task}
                    onDeleteTask={handleDeleteTask}
                    onToggleTaskCompletion={handleToggleTaskCompletion}/>
            ))}
        </ul>
    );
};

export default TaskList;
