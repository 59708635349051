import React from "react";
import {Link} from "react-router-dom";

const TaskListItem = ({task, onDeleteTask, onToggleTaskCompletion}) => {
    return (
        <li style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center'}}>
              <span onClick={() => onToggleTaskCompletion(task.id)}
                    className={task.completed ? 'completed' : ''}>{task.name}</span>
                    <button onClick={() => onDeleteTask(task.id)}>Delete</button>
                    <Link to={`tasks/` + task.id}>Edit</Link>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                <span style={{ margin: '10px' }}>{task.due}</span>
                <span style={{ margin: '10px' }}>{task.timeZone}</span>
                <span style={{ margin: '10px' }}>{task.estimatedDuration}</span>
            </div>
        </li>
    )
}

export default TaskListItem