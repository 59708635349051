import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import TaskFormLoader from "./components/TaskFormLoader";
import {ThemeProvider} from "./components/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById('root'));


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "tasks",
        element: <TaskFormLoader/>,
    },
    {
        path: "tasks/:taskId",
        element: <TaskFormLoader/>,
    },
]);


root.render(
    <React.StrictMode>
        <ThemeProvider>
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
