import ThemeToggleButton from "../components/ThemeToggleButton";
import React from "react";

const Header = () => {
    return (
        <>
            <div>Task List</div>
            <ThemeToggleButton/>
        </>
    )
}

export default Header