import React from "react";
import aryIanaTimeZones from "./timezoneList";

const TaskForm = ({task, setTask, onAddTask}) => {

    const handleChange = (e) => {
        setTask({...task, [e.target.name]: e.target.value});
    };

    const handleCheckboxChange = (e) => {
        setTask({...task, [e.target.name]: e.target.checked});
    }

    return (
        <div className="input-container">
            <div style={{display: "flex", flexDirection: "column"}}>
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={task.name}
                    onChange={handleChange}
                />
                <label htmlFor="due">Due</label>
                <input
                    type="datetime-local"
                    id="due"
                    name="due"
                    value={task.due}
                    onChange={handleChange}
                />
                <label htmlFor="timeZone">Time Zone</label>
                <select name="timeZone" id="timeZone"
                        value={task.timeZone}
                        onChange={handleChange}>
                    {aryIanaTimeZones.map(o => (<option key={o} value={o}>{o}</option>))}
                </select>
                <label htmlFor="estimatedDuration">Estimated Duration</label>
                <input
                    type="text"
                    id="estimatedDuration"
                    name="estimatedDuration"
                    value={task.estimatedDuration}
                    onChange={handleChange}
                />
                <label htmlFor="completed">Completed</label>
                <input
                    type="checkbox"
                    id="completed"
                    name="completed"
                    checked={task.completed}
                    onChange={handleCheckboxChange}
                />
                <button onClick={() => onAddTask(task)}>Save</button>
            </div>
        </div>
    )
}

export default TaskForm